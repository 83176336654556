<template>
  <ba-dialog
    :show="dialog"
    v-on:show-dialog="dialog = true"
    btnIcon="fa-history"
    title="Revoke Access"
    btnTooltip="Revoke Access"
    btnClass=""
    btnColor=""
    small
  >
    <v-flex xs12>
      <p>Please confirm you'd like to revoke the user's access from the current organization</p>
    </v-flex>
    <v-flex xs12 class="text-xs-right">
      <v-btn color="error" :loading="loading" @click="revoke"> Revoke </v-btn>
    </v-flex>
  </ba-dialog>
</template>

<script>
import gql from 'graphql-tag';

export default {
  props: ['user'],
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  methods: {
    async revoke() {
      this.loading = true;

      const vars = {
        orgId: this.$store.state.currentOrg.id,
        userId: this.user.id,
      };

      const resp = await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $userId: ID!) {
            removeOrgUser(orgId: $orgId, userId: $userId) {
              success
              errors
            }
          }
        `,
        variables: vars,
      });

      this.loading = false;
      this.closeDialog();

      if (resp.errors) {
        this.showErrorSnackbar('Problem revoking user: ' + resp.errors.join('<br />'));
      }

      if (resp.data && resp.data.removeOrgUser) {
        this.showSuccessSnackbar('User is successfuly revoked!');
        this.$emit('completed');
      }
    },

    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
