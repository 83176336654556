<template>
  <div class="pa-1">
    <v-layout align-center justify-end class="pb-3">
      <AddUser v-on:complete="inviteAdded" v-if="checkScope(scopeLiterals.UsersCreate)" />
    </v-layout>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="users"
      :pagination.sync="pagination"
      :loading="isLoading > 0"
      select-all
      item-key="name"
      class="elevation-3"
    >
      <v-progress-linear slot="progress" color="accent" height="2" indeterminate></v-progress-linear>
      <template slot="headers" slot-scope="props">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="[
              'column sortable text-xs-' + header.align,
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
            ]"
            @click="changeSort(header.value)"
          >
            <v-icon small>arrow_upward</v-icon>
            {{ header.text }}
          </th>
          <th></th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <tr :active="props.selected" @click="props.selected = !props.selected">
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.email }}</td>
          <td>{{ props.item.role.map((r) => r.name).join(', ') }}</td>
          <td class="text-xs-right">
            <RevokeUser v-on:completed="refresh" :user="props.item" v-if="checkScope(scopeLiterals.UsersDelete)" />
            <EditUser v-on:completed="refresh" :user="props.item" v-if="checkScope(scopeLiterals.UsersUpdate)" />
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import AddUser from './AddUser';
import EditUser from './EditUser';
import RevokeUser from './RevokeUser';

export default {
  props: [],
  data() {
    return {
      users: [],
      roleNames: {
        1: 'User',
        2: 'Writer',
        3: 'Owner',
        4: 'Admin',
      },
      pagination: {
        sortBy: 'name',
      },
      selected: [],
      headers: [
        {
          text: 'Name',
          align: 'left',
          value: 'name',
        },
        {
          text: 'Email',
          align: 'left',
          value: 'email',
        },
        {
          text: 'Role',
          align: 'left',
          value: 'role',
        },
      ],
      isLoading: 0,
    };
  },
  methods: {
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.desserts.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    inviteAdded() {
      this.$emit('switch', 'invitations');
      this.refresh();
    },
    refresh() {
      this.users = [];
      this.$apollo.queries.users.refetch();
    },
  },
  apollo: {
    users: {
      query: gql`
        query GetUsers($orgId: ID!) {
          org(id: $orgId) {
            id
            users {
              id
              name
              email
              role {
                id
                name
              }
              assertedIdentity
              walletIds
            }
          }
        }
      `,
      update(data) {
        return data.org.users;
      },
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  components: {
    AddUser: AddUser,
    RevokeUser: RevokeUser,
    EditUser: EditUser,
  },
};
</script>
