<template>
  <ba-dialog
    :show="dialog"
    v-on:show-dialog="
      resetForm();
      dialog = true;
    "
    btnIcon="edit"
    title="Edit"
    btnTooltip="Edit"
    btnClass=""
    btnColor=""
  >
    <v-container fluid grid-list-xl>
      <v-layout wrap align-center>
        <v-flex xs6 sm6 md6>
          <v-form ref="invite-form" v-model="valid">
            <ui-text-edit :label="$tc('_name')" v-model="name" :rules="[rules.required]"></ui-text-edit>
            <ui-text-edit :label="$tc('_email')" :value="user.email" disabled></ui-text-edit>
            <ui-select2
              class="tw-flex-1 tw-mr-2"
              :items="roles"
              :label="$tc('_role')"
              v-model="selectedRoles"
              keyField="id"
              labelField="name"
              keyOnly
              searchable
              multiple
              retainFocus
              checkboxes
            ></ui-select2>
            <ui-select2
              :items="wallets"
              v-model="selectedWallets"
              class="tw-flex-1 tw-mr-2"
              style="min-width: 180px"
              keyField="id"
              labelField="name"
              label="Wallets"
              searchable
              keyOnly
              multiple
              retainFocus
              checkboxes
              :isValid="selectedWallets && selectedWallets.length <= 10"
              validationMessage="Maximum of 10 wallets can be selected"
              :v-if="checkFeatureFlag('advanced-rbac')"
            ></ui-select2>
            <ui-text-edit label="Asserted Identity" v-model="assertedIdentity"></ui-text-edit>
          </v-form>
        </v-flex>
        <v-flex xs12 class="text-xs-right">
          <ui-button color="primary" :loading="loading" @click.native.stop="save" :disabled="!canSave">
            Save
          </ui-button>
        </v-flex>
      </v-layout>
    </v-container>
  </ba-dialog>
</template>

<script>
import gql from 'graphql-tag';

import { WalletsQuery } from '../../queries/transactionsPageQuery';
import UiButton from '../ui/UiButton.vue';
import UiSelect2 from '../ui/UiSelect2.vue';
import UiTextEdit from '../ui/UiTextEdit.vue';

export default {
  props: ['user'],
  data() {
    return {
      dialog: false,
      loading: false,
      name: this.user.name,
      assertedIdentity: this.user.assertedIdentity,
      valid: false,
      rules: {
        required: (v) => !!v || 'Field is required',
      },
      // roles: ['Read-only', 'Standard User', 'Writer', 'Owner', 'Admin', 'Advanced User'],
      roles: [],
      // rolesFiltered: ['Read-only', 'Standard User', 'Admin', 'Advanced User'],
      selectedRoles: this.user.role.map((r) => r.id),
      isLoadingWallets: 0,
      isRolesLoading: 0,
      selectedWallets: this.user.walletIds,
      wallets: [],
    };
  },
  computed: {
    canSave() {
      return (
        this.name !== this.user.name ||
        this.selectedRoles !== this.user.role ||
        this.assertedIdentity !== this.user.assertedIdentity ||
        this.selectedWallets !== this.user.walletIds
      );
    },
  },
  methods: {
    async save() {
      this.loading = true;

      let nameMutation = Promise.resolve();
      const roleMutation = Promise.resolve();
      let assertIdMutation = Promise.resolve();

      if (this.name !== this.user.name) {
        nameMutation = this.$apollo.mutate({
          // Query
          mutation: gql`
            mutation ($userId: ID!, $user: UserInput!) {
              updateUser(userId: $userId, user: $user) {
                success
                errors
              }
            }
          `,
          // Parameters
          variables: {
            userId: this.user.id,
            user: {
              displayName: this.name,
            },
          },
        });
      }

      // if (this.role !== this.user.role) {
      //   roleMutation = this.$apollo.mutate({
      //     mutation: gql`
      //       mutation ($orgId: ID!, $userRoles: [UserRoleInput]!) {
      //         updateOrg(orgId: $orgId, userRoles: $userRoles) {
      //           id
      //           userRoles {
      //             role
      //           }
      //         }
      //       }
      //     `,
      //     variables: {
      //       orgId: this.$store.state.currentOrg.id,
      //       userRoles: [
      //         {
      //           userId: this.user.id,
      //           role: this.roles.indexOf(this.role),
      //         },
      //       ],
      //     },
      //   });
      // }

      // if (this.assertedIdentity !== this.user.assertedIdentity) {
      assertIdMutation = this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $userId: ID!, $orgUser: OrgUserInput!) {
            updateOrgUser(orgId: $orgId, userId: $userId, orgUser: $orgUser) {
              id
            }
          }
        `,
        variables: {
          orgId: this.$store.state.currentOrg.id,
          userId: this.user.id,
          orgUser: {
            assertedIdentity: this.assertedIdentity,
            role: this.selectedRoles,
            walletIds: this.selectedWallets,
          },
        },
      });
      // }

      let error;

      await Promise.all([nameMutation, roleMutation, assertIdMutation])
        .then(([r1, r2, r3]) => {
          error = r1?.errors || r2?.errors || r3?.errors;
        })
        .catch((err) => {
          error = err;
        });

      this.loading = false;

      if (error) {
        this.showErrorSnackbar('Failed to edit the user. Please try again later');
      } else {
        this.showSuccessSnackbar('User is successfully updated!');
        this.closeDialog();
      }
    },

    closeDialog() {
      this.resetForm();
      this.dialog = false;
      this.$emit('completed');
    },

    resetForm() {
      this.name = this.user.name;
      this.selectedRoles = this.user.role.map((r) => r.id);
      this.assertedIdentity = this.user.assertedIdentity;
      this.selectedWallets = this.user.walletIds;
    },
  },
  apollo: {
    wallets: {
      query: WalletsQuery,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      update(data) {
        return [{ id: 'All', name: 'All Wallets' }, ...data.wallets.map((w) => ({ id: w.id, name: w.name }))];
      },
      loadingKey: 'isLoadingWallets',
    },
    roles: {
      query: gql`
        query getRoles($orgId: ID!) {
          roles(orgId: $orgId) {
            id
            name
            description
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      update(data) {
        return data.roles;
      },
      loadingKey: 'isRolesLoading',
    },
  },
  components: {
    UiSelect2,
    UiTextEdit,
    UiButton,
  },
};
</script>
